import { render, staticRenderFns } from "./applyForNewPassword.vue?vue&type=template&id=6ee1c32a&scoped=true&"
import script from "./applyForNewPassword.vue?vue&type=script&lang=js&"
export * from "./applyForNewPassword.vue?vue&type=script&lang=js&"
import style0 from "./applyForNewPassword.vue?vue&type=style&index=0&id=6ee1c32a&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6ee1c32a",
  null
  
)

export default component.exports